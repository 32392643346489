.nav {
  width: 100vw;
  height: 53px;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #000;
}

.navContent {
  align-items: baseline;
}

.title,
.link {
  margin-left: 15px;
  align-items: baseline;
  text-decoration: none;
}

.title {
  font-size: 26px;
}

.link {
  font-size: 18px;
}

.link:hover {
  cursor: pointer;
  text-decoration: underline;
}