.question {
  font-size: 24px;
  margin-left: 10px;
  max-width: 1200px;
  padding-left: 8px;
  border-left: 2px solid #000;
}

.questionHeader {
  font-size: 28px;
  margin-bottom: 10px;
}

.complexityValues {
  margin-left: 15px;
}

.loadedData {
  margin-left: 5px;
  font-size: 18px;
  padding: 0px;
}

pre {
  margin: 0px;
  padding: 0px;
}