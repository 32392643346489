.menu {
  margin-top: 3px;
  width: 248px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

.search {
  margin-top: 10px;
  margin-bottom: 10px;
}

.divider {
  width: 225px;
  height: 2px;
  background-color: #000;
}

.questions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.questionName {
  width: 210px;
  text-align: center;
  padding-top: 2px;
  padding-bottom: 5px;
  border-bottom: 1px solid #333;
}

.questionName:hover {
  text-decoration: underline;
  cursor: pointer;
  transition: 0.3;
}
